import Utility from './Utility';

class DomManipulator {

  static dm_instance = null;

  constructor() {
    this.dm_queue = [];
    this.dm_blocked_queue = [];
  }

  static instance() {
    if (!DomManipulator.dm_instance) {
      DomManipulator.dm_instance = new DomManipulator();
    }
    return DomManipulator.dm_instance;
  }

  addToQueue = async ({
    requires,
    methods,
    unblocks
  } = {}) => {
    if (requires != '') {
      if (!this.dm_blocked_queue[requires]) {
        this.dm_blocked_queue[requires] = {
          methods: methods,
          status: 'waiting'
        }
      } else if (this.dm_blocked_queue[requires].status == 'waiting') {
        this.dm_blocked_queue[requires].methods = this.dm_blocked_queue[requires].methods.concat(methods)
      } else {
        this.dm_queue = this.dm_queue.concat(methods)
        this.executeQueue();
      }
    } else {
      this.dm_queue = this.dm_queue.concat(methods)
      this.executeQueue();

      if (unblocks != '') {
        if (!this.dm_blocked_queue[unblocks]) {
          this.dm_blocked_queue[unblocks] = {
            methods: [],
            status: 'cleared'
          }
        } else {
          this.dm_blocked_queue[unblocks].status = 'cleared'
          this.dm_queue = this.dm_queue.concat(this.dm_blocked_queue[unblocks].methods)
          this.executeQueue();
        }
      }
    }
  }

  executeQueue = async (cb, size = null, sizeInclusive = false) => {
    const sizeCheckedCb = (...params) => {
      if (Array.isArray(cb)) {
        cb.map(cb => {
          if (Utility.isScreenSizeOf(size, sizeInclusive)) {
            cb(...params);
          }
        });
      } else {
        if (Utility.isScreenSizeOf(size, sizeInclusive)) {
          cb(...params);
        }
      }
    }

    if (Array.isArray(cb)) {
      this.dm_queue = this.dm_queue.concat(sizeCheckedCb)
    } else {
      if (cb) {
        this.dm_queue.push(sizeCheckedCb)
      }
    }
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      while (this.dm_queue.length) {
        try {
          const cb = this.dm_queue.shift()
          if (cb) {
            if (cb.constructor.name === "AsyncFunction") {
              await cb();
            } else {
              cb();
            }
          }
        } catch (e) {
          // continue regardless of error
          console.log(e)
        }
      }
    } else {
      document.addEventListener('DOMContentLoaded', () => {
        while (this.dm_queue.length) {
          try {
            const cb = this.dm_queue.shift()
            if (cb) {
              cb();
            }
          } catch (e) {
            // continue regardless of error
            console.log(e)
          }
        }
      });
    }
  }

}

export default DomManipulator.instance();
