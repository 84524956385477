import _ from 'lodash';

class Utility {
  // Copies keys and values from source to destination if the key is undefined
  // at the destination.

  static getBrowserWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }

  static getConfigData(config) {
    return new Promise((resolve, reject) => {
      const rejectionTimeout = setTimeout(() => {
        reject();
      }, 5000);
      EventManager.on('config_data_loaded', () => {
        const configInfo = _.find(window.MConstants.configs, config);
        if (configInfo) {
          clearTimeout(rejectionTimeout);
          resolve(configInfo.response);
        } else {
          reject();
        }
      })

    })
  }

  static getDeviceType = () => {
    const mqls = [window.matchMedia("(max-width: 767px)"), window.matchMedia("(max-width: 1169px)"), window.matchMedia("(min-width: 1170px)")];

    if (mqls[0].matches) {
      return 'm';
    } else if (!mqls[0].matches && mqls[1].matches) {
      return 't'
    } else {
      return 'dt'
    }
  }

  static isScreenSizeOf(size, inclusive = false) {
    const currentScreenSize = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    switch (size) {
      case 'xs':
        return inclusive ? currentScreenSize >= 480 : currentScreenSize > 480;
      case 'sm':
        return inclusive ? currentScreenSize >= 640 : currentScreenSize > 640;
      case 'md':
        return inclusive ? currentScreenSize >= 768 : currentScreenSize > 768;
      case 'lg':
        return inclusive ? currentScreenSize >= 1024 : currentScreenSize > 1024;
      case 'ticker-xl':
        return inclusive ? currentScreenSize >= 1170 : currentScreenSize > 1170;
      case 'xl':
        return inclusive ? currentScreenSize >= 1280 : currentScreenSize > 1280;
      case 'xxl':
        return inclusive ? currentScreenSize >= 1580 : currentScreenSize > 1580;
      default:
        return true
    }
  }

  static arrayDiff(original, subtractor) {
    return original.filter(e => subtractor.indexOf(e) < 0);
  }

  static arrayReverseInclude(arr, val) {
    return arr.some(v => val.includes(v))
  }

  static isSupported(klass) {
    if (!klass) return false;
    try {
      new (klass)();
    } catch(e) {
      return false;
    }
    return true;
  }

  static stringToHtml(str) {
    // If DOMParser is supported, use it
    if (Utility.isSupported(window.DOMParser)) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(str, 'text/html');
      return doc.body.firstChild;
    }

    // Otherwise, fallback to old-school method
    const dom = document.createElement('div');
    dom.innerHTML = str;
    return dom;
  }

  static isVisible(element) {
    if (!element) return false;

    const rect = element.getBoundingClientRect();
    const doc = document.documentElement;

    return (
      rect.height > 0 &&
      rect.width > 0 &&
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || doc.clientHeight) &&
      rect.left <= (window.innerWidth || doc.clientWidth)
    );
  }

  static getUrlParam(paramName) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(paramName)
  }

  static getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        var value = c.substring(name.length, c.length)
        if (value == 0) {
          return 'unknown';
        } else {
          return decodeURIComponent(c.substring(name.length, c.length));
        }
      }
    }
    return 'unknown';
  }

  static insertJS(jsData, isAsync = false, fileName = false ) {
    const scriptTag = document.createElement("script");
    scriptTag.async = isAsync;
    scriptTag.type = 'text/javascript';
    if (fileName) {
      scriptTag.src = jsData;
    } else {
      scriptTag.text = jsData;
    }
    const insertionNode = document.getElementsByTagName("script")[0];
    insertionNode.parentNode.insertBefore(scriptTag, insertionNode);
  }

  static setLocalCookies(name, value, expirationCookieName, updatePocklington = false, counterName = null, counterValue = null) {
    const maxTimestampInMilliseconds = (Math.pow(2, 31) - 1) * 1000; // maximum 32-bit signed int, * 1000 to get number of milliseconds
    const date = new Date(maxTimestampInMilliseconds);
    this.setCookieWithExpiration(name, value, date);
    if (expirationCookieName) {
      this.setCookieWithExpiration(expirationCookieName, maxTimestampInMilliseconds, date);
    }
    if (counterName) {
      this.setCookieWithExpiration(counterName, counterValue, date);
    }

    if (updatePocklington) {
      let cookies = {};
      cookies[name] = value;
      cookies[expirationCookieName] = expirationCookieName;
      if (counterName) cookies[counterName] = counterValue;

      Utility.updatePocklington(cookies);
    }
  }

  static setCookieWithExpiration(name, value, expirationDate, local = false) {
    let cookieString = `${name}=${value};expires=${expirationDate}`;
    if (expirationDate === 'session') {
      cookieString = `${name}=${value}`;
    }
    if (local == false) {
      cookieString += ';path=/'
    }
    if (BrowserCompatibility.instance().shouldSendSameSiteNone(window.navigator.userAgent)) {
      cookieString += ';SameSite=None'
    }
    if (window.location.protocol === 'https:') {
      cookieString += `;secure`;
    }
    document.cookie = cookieString;
  }

  static updatePocklington(cookies) {
    var URL = '//pocklington.mitremedia.com/survey.js';
    var script = document.createElement('script');
    var url = URL + '?' + Utility.serialize(cookies);
    script.src = window.location.protocol + url;
    document.body.appendChild(script);
  }

  static saveToLocalStorage(key, value) {
    if (_.isObject(value)) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value)
    }
  }

  static getFromLocalStorage(key) {
    const value = localStorage.getItem(key);
    try {
      const result = JSON.parse(value)
      if (_.isNull(result)) {
        return {}
      }
      return result;
    } catch (e) {
      if (_.isInteger(value)) {
        return parseInt(value)
      } else if (_.isNumber(value)) {
        return parseFloat(value)
      }
      return value;
    }
  }

  static getTableData(tableKey, datapointKey) {
    return Utility.getFromLocalStorage(datapointKey) ? Utility.getFromLocalStorage(datapointKey)[tableKey] : null
  }

  static setTableData(tableKey, datapointKey, data) {
    const dataInStorage = Utility.getFromLocalStorage(datapointKey);
    if (_.isEmpty(dataInStorage)) {
      Utility.saveToLocalStorage(datapointKey, {
        [tableKey]: data
      })
    } else {
      const allData = Object.assign({}, dataInStorage);
      allData[tableKey] = data;
      Utility.saveToLocalStorage(datapointKey, allData);
    }
  }

  static serialize = (obj) => {
    let str = [];
    for (let p in obj)
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  static insertToArray = (arr, row, col, value) => {
    while (!arr[row]) {
      arr.push([])
    }
    while (!arr[row][col]) {
      arr[row].push(' ')
    }
    arr[row][col] = value;
  }

  static reorganizeCells = nodeList => {
    const grid = [];
    nodeList.forEach(cell => {
      Utility.insertToArray(grid, cell.dataset.row, cell.dataset.col, cell)
    })
    return grid;
  }

  static toCurrency = (amount, precision = 2) => {
    if (amount) {
      return `$${amount.toFixed(precision)}`
    } else {
      return 'N/A'
    }
  }

  static toPercentage = amount => {
    if (amount) {
      return `${(amount * 100).toFixed(2)}%`
    } else {
      return 'N/A'
    }
  }

  static toTwelveTimeFormat = time => {
    if (time) {
      return time.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
    } else {
      return 'N/A'
    }
  }

  static formatDate = time => {
    if (time) {
      return time.toLocaleString('en-US', {
        month: 'short',
        day: 'numeric'
      })
    } else {
      return 'N/A'
    }
  }

  static showHideSliderArrow = (slider, left, right, greyOutColor, activeColor) => {
    slider.addEventListener('scroll', () => {
      if(slider.scrollLeft == 0) {
        left.classList.add(greyOutColor);
        left.classList.remove(activeColor);
      } else {
        left.classList.add(activeColor);
        left.classList.remove(greyOutColor);
      }
      if(slider.scrollLeft == slider.scrollWidth - slider.offsetWidth) {
        right.classList.add(greyOutColor);
      } else {
        right.classList.remove(greyOutColor);
      }
    });
  }

  static hexToAscii = str => {
    const hex = str.toString().slice(2);
    let result = '';
    for (let n = 0; n < hex.length; n += 2) {
      result += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return result;
  }

  static debounce = (cb, delay) => {
    var timeout = null;
    const debouncedCb = () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(cb, delay);
    }
    return debouncedCb;
  }

  static isHex = str => {
    return str.startsWith('0x')
  }

  static processImports = async imports => {
    const importPromises = [];
    const resolvers = [];
    imports.forEach(({loadTarget, resolve}) => {
      const chunkPromise = [];
      loadTarget.forEach(load => {
        chunkPromise.push(load);
      })
      importPromises.push(Promise.all(chunkPromise))
      resolvers.push(resolve);
    })

    const resolvedPromiseData = await Promise.all(importPromises);
    resolvedPromiseData.forEach((resolvedLoad, index) => {
      const resolver = resolvers[index];
      if (resolver) {
        resolver(resolvedLoad.map(x => x.default))
      }
    })
  }

  static resetSession = (keyPrefix) => {
    const prefixReg = new RegExp(keyPrefix);
    for (const key in sessionStorage) {
      if(prefixReg.test(key)) {
        sessionStorage.removeItem(key);
      }
    }
  }
}

class BrowserCompatibility {

  static _instance = null;

  static instance = () => {
    if (BrowserCompatibility._instance == null) {
      BrowserCompatibility._instance = new BrowserCompatibility();
    }
    return BrowserCompatibility._instance;
  }
  // Don’t send `SameSite=None` to known incompatible clients.
  shouldSendSameSiteNone = useragent => {
    return !this.isSameSiteNoneIncompatible(useragent)
  }

  // Classes of browsers known to be incompatible.
  isSameSiteNoneIncompatible = (useragent) => {
    return this.hasWebKitSameSiteBug(useragent) ||
      this.dropsUnrecognizedSameSiteCookies(useragent);
  }

  hasWebKitSameSiteBug = (useragent) => {
    return this.isIosVersion({major: 12}, useragent) ||
      (this.isMacosxVersion({major: 10, minor: 14}, useragent) &&
      (this.isSafari(useragent) || this.isMacEmbeddedBrowser(useragent)))
  }

  dropsUnrecognizedSameSiteCookies = useragent => {
    if (this.isUcBrowser(useragent)) {
      return !this.isUcBrowserVersionAtLeast({major: 12, minor: 13, build: 2}, useragent)
    }
    return this.isChromiumBased(useragent) &&
      this.isChromiumVersionAtLeast({major: 51}, useragent) &&
      !this.isChromiumVersionAtLeast({major: 67}, useragent)
  }

  isIosVersion = ({ major }, useragent) => {
    const regex = /\(iP.+; CPU .*OS (\d+)[_\d]*.*\) AppleWebKit\//g
    // Extract digits from first capturing group.
    const regexResult = regex.exec(useragent);

    return regexResult !== null && regexResult[1] == major.toString()
  }

  isMacosxVersion = ({major, minor}, useragent) => {
    const regex = /\(Macintosh;.*Mac OS X (\d+)_(\d+)[_\d]*.*\) AppleWebKit\//g;
    // Extract digits from first and second capturing groups.
    const matchedResult = regex.exec(useragent);
    return (
      matchedResult !== null &&
      matchedResult[1] == major.toString() &&
      matchedResult[2] == minor.toString()
    )
  }

  isSafari = useragent => {
    const safari_regex = /Version\/.* Safari\//g
    return safari_regex.test(useragent) && !this.isChromiumBased(useragent)
  }

  isMacEmbeddedBrowser = useragent => {
    const regex = /^Mozilla\/[\.\d]+ \(Macintosh;.*Mac OS X [_\d]+\) AppleWebKit\/[\.\d]+ \(KHTML, like Gecko\)$/g
    return regex.test(useragent)
  }

  isChromiumBased = useragent => {
    const regex = /Chrom(e|ium)/g;
    return regex.test(useragent);
  }

  isChromiumVersionAtLeast = ({major}, useragent) => {
    // Extract digits from first capturing group.
    const regex = /Chrom[^ \/]+\/(\d+)[\.\d]* /g
    const version = parseInt(regex.exec(useragent)[1])
    return version >= major
  }

  isUcBrowser = useragent => {
    const regex = /UCBrowser\//g
    return regex.test(useragent);
  }

  isUcBrowserVersionAtLeast = ({major, minor, build}, useragent) => {
    const regex = /UCBrowser\/(\d+)\.(\d+)\.(\d+)[\.\d]* /g
    // Extract digits from three capturing groups.
    const regexResult = regex.exec(useragent);
    const major_version = parseInt(regexResult[1]);
    const minor_version = parseInt(regexResult[2]);
    const build_version = parseInt(regexResult[3]);
    if (major_version != major) {
      return major_version > major
    }
    if (minor_version != minor) {
      return minor_version > minor
    }
    return build_version >= build
  }
}

export default Utility;
