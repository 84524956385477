import DomManipulator from '../classes/DomManipulator';

const loadAsyncJsFiles = () => {
  const asyncJsFileDoms = document.querySelectorAll('.async-js');
  const jsFileSet = new Set();
  asyncJsFileDoms.forEach(jsDom => {
    const { jsFiles } = jsDom.dataset;
    JSON.parse(jsFiles).forEach(jsFile => {
      try {
        if (!jsFileSet.has(jsFile)) {
          jsFileSet.add(jsFile);
          try {
            DomManipulator.addToQueue({
              requires: '',
              methods: [() => {
                import(
                  /* webpackChunkName: "async-scripts" */
                  /* webpackPrefetch: true */
                  /* webpackPreload: true */
                  `../data_services${jsFile}`
                )
              }],
              unblocks: ''
            });
          }
          catch (e) {
            // continue regardless of error
          }
        }
      } catch (e) {
        // continue regardless of error
      }
    })
  })
}

DomManipulator.addToQueue({
  requires: '',
  methods: [loadAsyncJsFiles],
  unblocks: ''
})
